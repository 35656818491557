import React, {useEffect} from "react";
import { AuthContext } from "../providers/AuthProvider";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import image1 from "../image1.jpg"
import Spotify from "../Spotify_icon.png"
import Fountain from "../fountain-app-icon.png"
import BitcoinMag from "../bitcoin-mag-logo.png"
import AOS from 'aos';
import 'aos/dist/aos.css';

const Reading = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(()=> {
    AOS.init({duration: 3000});
  }, []);

  return (
    <div className='pageSection2'>
      <div className='title'>
      <h2>Written Word</h2>
      {/* <h4>By Source Node</h4> */}
      </div>
    <div className='podcasts'>
    
    <a href="https://bitcoinmagazine.com/authors/source-node" target="_blank" rel="noreferrer">
   <div className='overlay'>
   <img className='logo' src={BitcoinMag} alt="Bitcoin Magazine Logo"/>

 </div></a>



        </div>
       
    </div>

  );
};

export default Reading;



