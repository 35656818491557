import React, {useEffect}  from "react";
import { Header } from "semantic-ui-react";
import { AuthContext } from "../providers/AuthProvider";
import { useContext, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import BlogDisplay from "./Blog";
import Intro from "./Intro";
import Podcasts from "./Podcasts";
import Reading from "./Reading";
import Logo from "../Logo.JPG"
import YouTube from 'react-youtube';
import Transition from "../transition.png"
import seyr from "../seyr.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from "./Footer";
import Listening from "./Listening";



const Appearances = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();
  const [showHomepage, setShowHomepage] = useState(false);

  useEffect(()=> {
    AOS.init({duration: 3000});
  }, []);

  const handleContinueClick = () => {
    setShowHomepage(true);
  };

  const videoOptions = {
    width: '2009px',
    height: '1300px',
    aspectRatio: '16:9', // Set the desired aspect ratio (16:9 is a common aspect ratio)
    position: 'absolute',
    objectFit: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: 0,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    playerVars: {
      autoplay: 1,
      frameborder: 0,
      controls: 0,
      showinfo: 0,
      loop: 1,
      mute: 1,
      modestbranding: 0,
      playsinline: 0,
      rel: 0,
    },
  };


  return (

    
      <div data-aos="fade" className="homepage" >
          <Link to="/" state={{ showContent: true }} rel="noreferrer">
            <div className='pageSection4'> 
              <img className='logo-appearances' src={Logo} alt="Source Node Logo"/>
            </div>
          </Link>


        

 
    <div className='pageSection2'>
      <div className='title'>
      <h2>Appearances</h2>
      {/* <h4>By Source Node</h4> */}
      </div>
    <div className='podcasts'>
    
  <a href="https://youtu.be/Fz4DktImpVc?si=XRnEyMCoJtiLr9_m" target="_blank" rel="noreferrer">
  <p className='subtitle font'>July 21, 2024</p>
     <div className='overlay'>
     
     <img className='thumbnail' src={seyr} alt="Don't Trust USD thumbnail"/>
   
   {/* <button className='devButton'>Listen on Fountain</button> */}
   </div></a>


     {/* <a href="https://open.spotify.com/show/5peOhK15XYH9RAbItVmxac?si=DALaTfC8RZS0ArQiTSzqOw" target="_blank" rel="noreferrer">
     <div className='overlay'>
        <img className='logo' src={Spotify} alt="Spotify Logo"/>

   </div></a> */}



        </div>

    </div>

  
    

        <Footer/>
      </div>
    
  );
};

export default Appearances;
