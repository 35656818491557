import { useContext } from "react";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Menu } from "semantic-ui-react";
import { AuthContext } from "../providers/AuthProvider";

const Layout = () => {
  // x bad name for tutorial sake
  let x = useNavigate();

  const { authenticated, handleLogout } = useContext(AuthContext);
  const renderAuthLinks = () => {
    // if (authenticated) {
    //   return <button onClick={() => handleLogout(x)}>Logout</button>;
    // }
    // return (
    //   <>
    //     <div>
    //       <Link to="/register">Register</Link>
    //     </div>
    //     <div>
    //       <Link to="/login">Login</Link>
    //     </div>
    //   </>
    // );
  };
  return (
    <>
    {/* <Menu className='navbar' borderless style={{borderRadius: '0px', backgroundColor: 'black', marginBottom:'0px'}}>
    
        <div className="font">
          <Link style={styles.navbarText} to="/">Home</Link>
        </div>
        <div className="font">
          <Link style={styles.navbarText}  to="/protected">Protected</Link>
        </div>
        {renderAuthLinks()}
      <div style={styles.pageContainer}>
      </div>
    </Menu> */}

      <Outlet />
      </>
  );
};

const styles={
  content: {
    display: "flex",
    border: "1px solid",
    padding: "15px",
  },
    outlet: {
      backgroundColor: "lightgrey",
      padding: "15px",
  },
    navbar: {
      display: "flex",
      border: "1px solid",
    },
    navbarText: {
      textDecoration: 'none',
      color: 'white',
      margin: '10px',
      fontSize: '20px',
    },
};

export default Layout;