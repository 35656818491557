import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';


function BlogHomepage() {
  const [blogs, setBlogs] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]);

  useEffect(() => {
    fetchBlogs();
    fetchCategories();
  }, []);

  const fetchBlogs = async () => {
    try {
      const response = await axios.get('/blogs'); // Replace with the actual API endpoint for fetching blogs
      setBlogs(response.data);
    } catch (error) {
      console.error('Error fetching blogs:', error);
    }
  };

  const fetchCategories = async () => {
    try {
      const response = await axios.get('/categories'); // Replace with the actual API endpoint for fetching categories
      setCategories(response.data);
    } catch (error) {
      console.error('Error fetching categories:', error);
    }
  };

  const handleCategorySelect = (categoryId) => {
    setSelectedCategory(categoryId);
  };

  const filteredBlogs = selectedCategory
    ? blogs.filter((blog) => blog.category.id === selectedCategory)
    : blogs;

  return (
    <div className="blog-homepage">
      <h1>Blog Homepage</h1>
      <div className="category-menu">
        <button onClick={() => handleCategorySelect(null)}>All</button>
        {categories.map((category) => (
          <button key={category.id} onClick={() => handleCategorySelect(category.id)}>
            {category.name}
          </button>
        ))}
      </div>
      <div className="blog-list">
        {filteredBlogs.length > 0 ? (
          filteredBlogs.map((blog) => (
            <div key={blog.id}>
              <h2>{blog.title}</h2>
              <p>Author: {blog.author}</p>
              <p>{blog.preview}</p>
              <Link to={`/blogs/${blog.id}`}>
                <button>Read More</button>
              </Link>
            </div>
          ))
        ) : (
          <p>No blogs found.</p>
        )}
      </div>
    </div>
  );
}

export default BlogHomepage;
