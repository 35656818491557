import React, {useEffect} from "react";
import { AuthContext } from "../providers/AuthProvider";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Podcasts = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(()=> {
    AOS.init({duration: 3000});
  }, []);

  return (
    <div >
        <div  className="pageSection3">
        <iframe src="https://open.spotify.com/embed/episode/2zWZQA4SiaAJlrzlil2sNa?theme=0" width="100%" height="232" frameBorder="0" allowfullscreen="" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"></iframe>    
        </div>
      
        <div className='whiteBox'>
  <p>Brought to you by my only sponsor, Bitcoin.</p>

<p>If you enjoy my work and wish to contribute your gifts will be greatly appreciated 🤙</p>

<div className="greenBox">
<p className='paragraph'><strong>Bitcoin Address: </strong>bc1qkecy0jy9zef5p95ncqnfu0ahn0mtjr8vw5k40t</p>
</div>

</div>

    </div>
    

  );
};

export default Podcasts;