import React, {useEffect}  from "react";
import { AuthContext } from "../providers/AuthProvider";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';

const Intro = () => {
  const auth = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(()=> {
    AOS.init({duration: 3000});
  }, []);

  return (
    <div data-aos="fade" className='intro pageSection font' >
      <h1 className="titleFont">The Holotropic Renaissance</h1>
      {/* <p>Are we living in a simulation?</p> */}

<p>We are on the verge of entering a drastically different way of life on this planet. Many seem to be subtly aware of the shift that is taking place. Most are still trapped in a system that is not serving them.</p>

<p>The content below was created to provide guidance towards illuminating a path out of illusion. The hope embedded in every word is for the liberation of humanity.</p>

<p>Now is the time to embody a world where our children live in playful abundance rather than trauma and fear. A world where the water, soil, and air are free of contamination. A world where force and manipulation are replaced by truth and voluntary action. A world where every living cell is growing towards its greatest potential. A world where compassion permeates every interaction and the forces of darkness vanish in the brilliant light of the source of creation.</p>

<p>Fear not.</p> 

<p>This world already lives within us.</p>



      {/* <Link to="/protected">Protected</Link>
      <Link to="/public">Public</Link>
      <p onClick={()=>navigate("/public")}>navigate to public</p> */}

    </div>
  );
};

export default Intro;